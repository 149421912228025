<template>
    <div>
        <building-details-form
            ref="buildings"
            :item="item"
            :viewMode="viewMode"
        >
        </building-details-form>

        <div class="d-flex justify-content-between mt-6">
            <st-button size="large" variant="link" :callback="cancel">
                {{ $t("APPLICATION.MODALS.CANCEL") }}
            </st-button>
            <st-button v-if="!viewMode" size="large" :callback="submit">
                {{ $t("APPLICATION.MODALS.ADD") }}
            </st-button>
        </div>
    </div>
</template>

<script>
import { BuildingsModel } from "@/modules/applications/models/buildings-model";
import { FormSchema } from "@/shared/form/form-schema";
import { mapGetters } from "vuex";
import BuildingDetailsForm from "@/modules/applications/components/buildings/BuildingDetailsForm";

const { fields } = BuildingsModel;

const formSchema = new FormSchema([
    fields.name
])

export default {
    name: "BuildingsForm",
    components: {
        BuildingDetailsForm,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            fields,
            model: null,
            selectedEntity: null
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
        }),
    },
    methods: {
        async validateCorp() {
            const fieldRefs = this.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key] && fieldRefs[key].fields
            );

            const selectedEntity = fieldRefs[selectedEntityKey];
            const status = await selectedEntity.fv.validate();
            const buildingDetails = { ...selectedEntity.model, proposed_apartments_no: selectedEntity.proposed_apartments_no };
            return {
                status,
                buildingDetails,
            };
        },

        cancel() {
            this.$emit('cancelForm');
        },
        async submit() {
            const building = await this.validateCorp();

            if (building.status === 'Valid') {
                if (this.item) {
                    building.index = this.item.index;
                }

                this.$emit("submitForm", building);
            }
        },
    },
    created() {
        if (this.record?.form?.buildings) {
            this.model = formSchema.initialValues(this.record.form.buildings);
        } else {
            if (this.item) {
                this.model = formSchema.initialValues(this.item);
            } else {
                this.model = formSchema.initialValues({});
            }
        }
    },
    mounted() {
        this.selectedEntity = this.item ?? null;
    },
};
</script>
