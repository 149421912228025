<template>
    <form class="form" novalidate="novalidate" id="st_buildings_form">
        <div class="row">
            <div class="col-sm col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.name.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.name.name"
                            :name="fields.name.name"
                            v-model="model[fields.name.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
                <div class="form-group mt-4 mb-0">
                    <label>
                        {{ fields.building_function.label }}
                    </label>
                    <b-form-radio-group
                        size="lg"
                        v-model="model[fields.building_function.name]"
                        :name="fields.building_function.name"
                        :ref="fields.building_function.name"
                        :options="fields.building_function.options"
                        :state="building_func_state"
                        value-field="text"
                        :disabled="viewMode"
                    >
                    </b-form-radio-group>
                </div>
                <div class="form-group mb-6" v-if="model[fields.building_function.name] === existentBuildingOption">
                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            size="lg"
                            v-model="model[fields.building_func_existing.name]"
                            :name="fields.building_func_existing.name"
                            :ref="fields.building_func_existing.name"
                            :options="fields.building_func_existing.options"
                            :aria-describedby="ariaDescribedby"
                            :state="building_func_existing_state"
                            value-field="text"
                            stacked
                            :disabled="viewMode"
                        >
                        </b-form-radio-group>
                    </b-form-group>
                </div>
            </div>
            <div class="col-sm col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.function.label }}
                    </label>
                    <div>
                        <b-form-select
                            v-model="model[fields.function.name]"
                            :options="fields.function.options"
                            :ref="fields.function.name"
                            :name="fields.function.name"
                            :disabled="viewMode"
                            value-field="text"
                        >
                        </b-form-select>
                    </div>
                </div>
                <div class="row mt-4" v-if="model[fields.function.name] === clFunctionOption">
                    <div class="col-sm col-md-6 d-flex justify-content-between">
                        <b-form-group label="" v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                size="lg"
                                v-model="model[fields.cl_housing_types.name]"
                                :name="fields.cl_housing_types.name"
                                :ref="fields.cl_housing_types.name"
                                :options="clHousingTypesPrimary"
                                :aria-describedby="ariaDescribedby"
                                :state="cl_housing_types_state"
                                value-field="text"
                                stacked
                                :disabled="viewMode"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label="" v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                size="lg"
                                v-model="model[fields.cl_housing_types.name]"
                                :name="fields.cl_housing_types.name"
                                :ref="fields.cl_housing_types.name"
                                :options="clHousingTypesSecondary"
                                :aria-describedby="ariaDescribedby"
                                :state="cl_housing_types_state"
                                value-field="text"
                                stacked
                                :disabled="viewMode"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="col-sm col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.proposed_apartments_no.label }}
                            </label>
                            <st-input-text
                                :ref="fields.proposed_apartments_no.name"
                                :name="fields.proposed_apartments_no.name"
                                v-model="proposed_apartments_no"
                                :value="proposed_apartments_no"
                                :placeholder="fields.proposed_apartments_no.placeholder"
                                :disabled="true"
                            />
                        </div>
                        <div class="row">
                            <div class="col-md-6" v-for="(room, index) of mappedRooms" :key="index">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4 pl-2 pr-0 mt-3">
                                            <label class="">
                                                {{ fields[room.name].label }}
                                        </label>
                                        </div>
                                        <div class="col-md-8">
                                            <st-input-text
                                                :ref="fields[room.name].name"
                                                :name="fields[room.name].name"
                                                v-model="model[room.field.name]"
                                                :disabled="viewMode"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.category_of_importance.label }}
                    </label>
                    <div>
                        <b-form-select
                            v-model="model[fields.category_of_importance.name]"
                            :options="fields.category_of_importance.options"
                            :ref="fields.category_of_importance.name"
                            :name="fields.category_of_importance.name"
                            :disabled="viewMode"
                            value-field="text"
                        >
                        </b-form-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.built_area.label }}
                    </label>
                    <st-input-text
                        :ref="fields.built_area.name"
                        :name="fields.built_area.name"
                        v-model="model[fields.built_area.name]"
                        :disabled="viewMode"
                    />
                </div>
            </div>
            <div class="col-sm col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.developed_area.label }}
                    </label>
                    <st-input-text
                        :ref="fields.developed_area.name"
                        :name="fields.developed_area.name"
                        v-model="model[fields.developed_area.name]"
                        :disabled="viewMode"
                    />
                </div>
            </div>
            <div class="col-sm col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.useful_area.label }}
                    </label>
                    <st-input-text
                        :ref="fields.useful_area.name"
                        :name="fields.useful_area.name"
                        v-model="model[fields.useful_area.name]"
                        :disabled="viewMode"
                    />
                </div>
            </div>
            <div class="col-sm col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.living_area.label }}
                    </label>
                    <st-input-text
                        :ref="fields.living_area.name"
                        :name="fields.living_area.name"
                        v-model="model[fields.living_area.name]"
                        :disabled="viewMode"
                    />
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.height.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.height.name"
                            :name="fields.height.name"
                            v-model="model[fields.height.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-sm col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.max_height.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.max_height.name"
                            :name="fields.max_height.name"
                            v-model="model[fields.max_height.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-sm col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.existing_levels.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.existing_levels.name"
                            :name="fields.existing_levels.name"
                            v-model="model[fields.existing_levels.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
            <div class="col-sm col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.proposed_levels.label }}
                    </label>
                    <div>
                        <st-input-text
                            :ref="fields.proposed_levels.name"
                            :name="fields.proposed_levels.name"
                            v-model="model[fields.proposed_levels.name]"
                            :disabled="viewMode"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-2 col-md-6">
                <div class="form-group">
                    <label>
                        {{ fields.construction_type.label }}
                    </label>
                    <div>
                        <b-form-textarea
                            :ref="fields.construction_type.name"
                            :name="fields.construction_type.name"
                            v-model="model[fields.construction_type.name]"
                            :disabled="viewMode"
                            rows="3"
                            no-resize
                        />
                    </div>
                </div>
            </div>
            <div class="col-2 col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.foundations.label }}
                    </label>
                    <div>
                        <b-form-textarea
                            :ref="fields.foundations.name"
                            :name="fields.foundations.name"
                            v-model="model[fields.foundations.name]"
                            :disabled="viewMode"
                            rows="3"
                            no-resize
                        />
                    </div>
                </div>
            </div>
            <div class="col-2 col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.roof_type.label }}
                    </label>
                    <div>
                        <b-form-textarea
                            :ref="fields.roof_type.name"
                            :name="fields.roof_type.name"
                            v-model="model[fields.roof_type.name]"
                            :disabled="viewMode"
                            rows="3"
                            no-resize
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-2 col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.exterior_finish.label }}
                    </label>
                    <div>
                        <b-form-textarea
                            :ref="fields.exterior_finish.name"
                            :name="fields.exterior_finish.name"
                            v-model="model[fields.exterior_finish.name]"
                            :disabled="viewMode"
                            :placeholder="fields.exterior_finish.placeholder"
                            rows="3"
                            no-resize
                        />
                    </div>
                </div>
            </div>
            <div class="col-2 col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.exterior_woodwork.label }}
                    </label>
                    <div>
                        <b-form-textarea
                            :ref="fields.exterior_woodwork.name"
                            :name="fields.exterior_woodwork.name"
                            v-model="model[fields.exterior_woodwork.name]"
                            :disabled="viewMode"
                            :placeholder="fields.exterior_woodwork.placeholder"
                            rows="3"
                            no-resize
                        />
                    </div>
                </div>
            </div>
            <div class="col-2 col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.heating_type.label }}
                    </label>
                    <div>
                        <b-form-textarea
                            :ref="fields.heating_type.name"
                            :name="fields.heating_type.name"
                            v-model="model[fields.heating_type.name]"
                            :disabled="viewMode"
                            rows="3"
                            no-resize
                        />
                    </div>
                </div>
            </div>
            <div class="col-2 col-md-3">
                <div class="form-group">
                    <label>
                        {{ fields.isolation.label }}
                    </label>
                    <b-form-textarea
                        :ref="fields.isolation.name"
                        :name="fields.isolation.name"
                        v-model="model[fields.isolation.name]"
                        :disabled="viewMode"
                        :placeholder="fields.isolation.placeholder"
                        rows="3"
                        no-resize
                    />
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="form-group col-12">
                <label>
                    {{ fields.other_characteristics.label }}
                </label>
                <b-form-textarea
                    :ref="fields.other_characteristics.name"
                    :name="fields.other_characteristics.name"
                    v-model="model[fields.other_characteristics.name]"
                    :disabled="viewMode"
                    rows="3"
                    no-resize
                />
            </div>
        </div>
    </form>
</template>

<script>
import { BuildingsModel } from "@/modules/applications/models/buildings-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = BuildingsModel;

const formSchema = new FormSchema([
    fields.id,
    fields.name,
    fields.height,
    fields.max_height,
    fields.existing_levels,
    fields.proposed_levels,
    fields.construction_type,
    fields.foundations,
    fields.roof_type,
    fields.heating_type,
    fields.isolation,
    fields.exterior_finish,
    fields.exterior_woodwork,
    fields.function,
    fields.category_of_importance,
    fields.building_function,
    fields.building_func_existing,
    fields.built_area,
    fields.developed_area,
    fields.useful_area,
    fields.living_area,
    fields.proposed_apartments_no,
    fields.one_room_apartment_no,
    fields.two_rooms_apartment_no,
    fields.three_rooms_apartment_no,
    fields.four_rooms_apartment_no,
    fields.five_rooms_apartment_no,
    fields.other_rooms_apartment_no,
    fields.other_characteristics,
    fields.cl_housing_types,
]);

const mappedRooms = [
    {
        name: 'one_room_apartment_no',
        field: fields.one_room_apartment_no,
        index: 1,
    },
    {
        name: 'two_rooms_apartment_no',
        field: fields.two_rooms_apartment_no,
        index: 3,
    },
    {
        name: 'three_rooms_apartment_no',
        field: fields.three_rooms_apartment_no,
        index: 5,
    },
    {
        name: 'four_rooms_apartment_no',
        field: fields.four_rooms_apartment_no,
        index: 2,
    },
    {
        name: 'five_rooms_apartment_no',
        field: fields.five_rooms_apartment_no,
        index: 4,
    },
    {
        name: 'other_rooms_apartment_no',
        field: fields.other_rooms_apartment_no,
        index: 6,
    },
]
export default {
    name: "BuildingDetailsForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            fv: null,
            building_func_state: null,
            building_func_existing_state: null,
            cl_housing_types_state: null,
            mappedRooms: mappedRooms.sort((a, b) => a.index - b.index)
        };
    },
    computed: {
        clFunctionOption() {
            return this.fields.function.options.find((option) => option.value === "cl").text;
        },
        existentBuildingOption() {
            return this.fields.building_function.options.find((option) => option.value === "existing").text;
        },
        clHousingTypesPrimary() {
            return this.fields.cl_housing_types.options.filter((option) => option.type === "primary");
        },
        clHousingTypesSecondary() {
            return this.fields.cl_housing_types.options.filter((option) => option.type === "secondary");
        },
        roomsSum() {
            const roomsValues = [
                this.model.one_room_apartment_no ? Number(this.model.one_room_apartment_no): 0,
                this.model.two_rooms_apartment_no ? Number(this.model.two_rooms_apartment_no): 0,
                this.model.three_rooms_apartment_no ? Number(this.model.three_rooms_apartment_no): 0,
                this.model.four_rooms_apartment_no ? Number(this.model.four_rooms_apartment_no): 0,
                this.model.five_rooms_apartment_no ? Number(this.model.five_rooms_apartment_no): 0,
                this.model.other_rooms_apartment_no ? Number(this.model.other_rooms_apartment_no): 0,
            ]

            return  roomsValues.reduce((acc, val) => {
                return acc + val
            }, 0);
        },
        proposed_apartments_no: {
            get() {
                return this.roomsSum ?? 0
            },
            set(val) {
                this.$emit('proposed_apartments_no', val);
            },
        },
    },
    watch: {
        "model.building_function": {
            handler: function (val) {
                if (val && !this.viewMode) {
                    this.building_func_state = true;
                    this.$nextTick(() => {
                        this.fv.addField(this.fields.building_func_existing.name, {
                            validators: {
                                notEmpty: {
                                    message: this.$t('VALIDATION.REQUIRED_FIELD'),
                                },
                            },
                        });
                    })
                }
            },
        },
        "model.building_func_existing": {
            handler (val) {
                if (val && !this.viewMode) {
                    this.$nextTick(() => {
                        this.building_func_existing_state = true;
                    })
                }
            }
        },
        "model.function": {
            handler (val) {
                if (val && val === this.clFunctionOption) {
                    this.$nextTick(() => {
                        this.fv.addField(this.fields.cl_housing_types.name, {
                            validators: {
                                notEmpty: {
                                    message: this.$t('VALIDATION.REQUIRED_FIELD'),
                                },
                            },
                        });
                        mappedRooms.forEach(room => {
                            this.fv.addField(this.fields[room.name].name, {
                                validators: {
                                    notEmpty: {
                                        message: this.$t('VALIDATION.REQUIRED_FIELD'),
                                    },
                                },
                            });
                        });
                    })
                } else  {
                    const fields = this.fv.getFields();
                    if (fields[this.fields.cl_housing_types.name]) {
                        this.fv.removeField(this.fields.cl_housing_types.name);
                    }
                    mappedRooms.forEach(room => {
                        if (fields[this.fields[room.name].name]) {
                            this.fv.removeField(this.fields[room.name].name);
                        }
                    });
                }
            },
            immediate: false
        },
        "model.cl_housing_types": {
            handler (value) {
                if (value && !this.viewMode) {
                    this.cl_housing_types_state = true;
                }
            }
        },
    },
    async created() {
        if (this.item) {
            this.model = formSchema.initialValues(this.item);
        } else {
            this.model = formSchema.initialValues({});
        }
    },
    mounted() {
        this.fv = createFormValidation("st_buildings_form", this.rules);
        this.fv.on("core.element.validated", (data) => {
            if( data.field === 'building_function' && !data.valid) {
                this.building_func_state = false;
            }
            if (data.field === 'building_func_existing' && !data.valid) {
                this.building_func_existing_state = false;
            }
            if (data.field === 'cl_housing_types' && !data.valid) {
                this.cl_housing_types_state = false;
            }
        })
    },
};
</script>
