<template>
    <st-section
        :header="$t('BUILDINGS.LIST_HEADER')"
        customClass="list-section"
        id="buildings"
        :validate="!viewMode"
        :wasValidated="wasValidated"
        :validation="validationStatus"
    >
        <template #body>
            <carousel-3d
                :controls-visible="true"
                :width="400"
                :height="220"
                :space="300"
                :count="itemsComputed.length"
                dispay="3"
                ref="buildingsCarousel"
            >
                <slide v-for="(item, i) in itemsComputed" :index="i" :key="i">
                    <div class="carousel-card real-estate-card">
                        <building-item-card
                            v-if="!item.isPlaceholder"
                            @edit="onEdit"
                            @view="onView"
                            @delete="onDelete"
                            :item="item"
                            :index="i"
                            :viewMode="viewMode"
                            :isStaff="isStaff"
                        ></building-item-card>
                        <div class="card-user-placeholder" v-else>
                            <i class="fas fa-home"></i>
                        </div>
                    </div>
                </slide>
            </carousel-3d>
            <div class="d-flex list-footer">
                <div class="col-9 d-flex align-items-center px-0">
                    <span>{{ total }}</span>
                </div>
                <div
                    class="col-3 d-flex justify-content-end px-0"
                    v-if="!viewMode"
                >
                    <st-button :callback="openBuildingsModal" variant="link">
                        <span class="d-flex align-items-center">
                            <i class="fas fa-plus"></i>
                            {{ $t("BUILDINGS.FORM.ADD_BTN") }}
                        </span>
                    </st-button>
                </div>
            </div>

            <buildings-modal
                ref="buildingsModal"
                :item="selectedEntity"
                @processBuilding="processBuilding"
                @cancel="cancel"
            ></buildings-modal>
        </template>
    </st-section>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { BuildingsModel } from "@/modules/applications/models/buildings-model";
import BuildingsModal from "@/modules/applications/components/buildings/BuildingsModal.vue";
import BuildingItemCard from "@/modules/applications/components/buildings/BuildingItemCard.vue";

const { fields } = BuildingsModel;

export default {
    name: "BuildingsList",
    components: {
        BuildingsModal,
        BuildingItemCard,
        Carousel3d,
        Slide,
    },
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        wasValidated: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            items: [],
            selectedEntity: null,
            selectedEntityIndex: null,
        };
    },
    watch: {
        record: {
            deep: true,
            immediate: true,
            handler(value) {
                this.items = value?.form?.buildings ?? value?.form_staff?.buildings ?? [];
                this.$emit("changeList", this.items, "buildings", true);
            },
        },
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            isStaff: "auth/isStaff",
        }),
        fields() {
            fields.address.tdClass = "d-flex col-md-6 align-items-center";
            return [
                fields.address,
            ];
        },
        total() {
            return this.items?.length === 1
                ? this.$t("BUILDINGS.SINGLE", { total: 1 })
                : this.$t("BUILDINGS.MULTIPLE", { total: this.items.length });
        },
        itemsComputed() {
            const countItems = this.items?.length;
            if (!countItems >= 3) return [...this.items];
            const previewItems = 3;
            let visibleItems = [];
            if (countItems > 0) {
                visibleItems = [...this.items];
            }
            const placeholderItems = previewItems - countItems;
            for (let i = 0; i < placeholderItems; i += 1) {
                visibleItems.push({
                    isPlaceholder: true,
                });
            }

            return visibleItems;
        },
        validationStatus() {
            return this.items?.length ? "success" : "error";
        },
    },
    methods: {
        openBuildingsModal() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
            this.$refs.buildingsModal.show();
        },
        async processBuilding(data) {
            const isEdit = Object.keys(data).some((el) => el === "index");
            if (isEdit) {
                Vue.set(this.items, data.index, data.buildingDetails);
            } else {
                this.items.push({
                    ...data.buildingDetails,
                    index: this.items.length,
                });
                this.goToSlide(this.items.length - 1);
            }
            this.$emit("changeList", this.items, "buildings", true);

            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        onEdit({ item, index }) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            this.$refs.buildingsModal.show();
        },
        onDelete({ index }) {
            this.items.splice(index, 1);
            this.goToSlide(0);
        },
        onView({ item, index }) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            const viewMode = true;
            this.$refs.buildingsModal.show(viewMode);
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        goToSlide(index) {
            this.$refs.buildingsCarousel.goSlide(index);
        },
    },
    created() {
        this.items = [];
        if (this.$route.params.id) {
            this.items = this.record?.form?.buildings ?? [];
        }
    },
};
</script>
