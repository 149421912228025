<template>
    <div>
        <div class="d-flex flex-row-reverse">
            <div class="col col-4 p-0" v-if="!viewMode">
                <div class="card-action-group">
                    <div class="card-action" @click="$emit('edit', {item, index})">
                        <i class="fas fa-edit"></i>
                    </div>
                    <div class="card-action" @click="$emit('delete', {item, index})">
                        <i class="fas fa-trash"></i>
                    </div>
                </div>
            </div>
            <div v-if="isStaff && viewMode">
                <div class="card-action" @click="$emit('view', {item, index})">
                    <i class="fas fa-eye"></i>
                </div>
            </div>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("BUILDINGS.CARD.NAME") }}: </span>
            <span>{{ item.name }}</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("BUILDINGS.CARD.HEIGHT_SHORT") }}: </span>
            <span>{{ item.height }}, {{ item.max_height }}</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("BUILDINGS.CARD.NR_LEVELS") }}: </span>
            <span>{{ item.existing_levels }}, {{ item.proposed_levels }}</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("BUILDINGS.CARD.CHARACTERISTICS_SHORT") }}: </span>
            <span>{{ item.construction_type }}, ...</span>
        </div>
        <div>
            <span class="font-weight-bold">{{ $t("BUILDINGS.CARD.CAPACITY_SHORT") }}: </span>
            <span>{{ item.function }}</span>
        </div>
    </div>
</template>
<script>

export default {
    name: 'BuildingItemCard',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        viewMode: {
            type: Boolean,
            default: false,
        },
        isStaff: {
            type: Boolean,
            default: false,
        },
    }
}
</script>
